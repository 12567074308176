@import 'base/reboot';
@import 'base/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

[v-cloak] {
  visibility: hidden;
  opacity: 0;
}

html {
  font-family: 'Roboto', sans-serif;
  min-height: 100%; height: 100%;
  color: map-get($colors, text);
}
body {
  height: 100%;
  background: map-get($colors, background);
}
.app {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.logo {
  max-width: 150px;
  width: 100%;
  margin-bottom: 20px;
}

.link {
  color: map-get($colors, text);
  font-weight: 500;
}

h1.heading {
  color: map-get($colors, text);
  font-size: 25px;
}

h2.heading {
  color: map-get($colors, text);
  font-size: 21px;
}

h3.heading {
  color: map-get($colors, text);
  font-size: 18px;
}

.paragraph {
  color: map-get($colors, text-subtle);
  font-size: 16px;
}

.privacy {
  padding: 30px;
}
